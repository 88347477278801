import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import styles from '../styles/myOffers.module.css'
import check from '../Images/check.svg'
import feather from '../Images/feather.svg'
import play2 from '../Images/play2.svg'
import arrowDown from '../Images/arrowDown.svg'
import arrowUp from '../Images/arrowUp.svg'
import heart2 from '../Images/heart2.svg'
import archive from '../Images/archive.svg'
import { Context } from "..";
import { useNavigate, useParams } from "react-router-dom";
import { EDIT_OFFER, FREELANCE_CATALOG } from "../utils";
import OfferService from "../service/OfferService";
import edit from '../Images/edit.svg'
import { toast } from "react-toastify";

function MyOffers() {

    const { offerStore } = useContext(Context)

    const params = useParams()
    const current = params.id

    const navigate = useNavigate()

    const [showOffers, setShowOffers] = useState('inprogress')
    const [user, setUser] = useState('ispolnitel')
    const [draftOffers, setDraftOffers] = useState([])
    const [doneOffers, setDoneOffers] = useState([])
    const [activeOffers, setActiveOffers] = useState([])
    const [inprogressOffers, setInprogressOffers] = useState([])
    const [doneOffersIsp, setDoneOffersIsp] = useState([])
    const [inprogressOffersIsp, setInprogressOffersIsp] = useState([])
    const [favoritesOffersIsp, setFavoritesOffersIsp] = useState([])

    useEffect(() => {
        getDraftOffers()
        getDoneOffers()
        getActiveOffers()
        getInprogressOffers()
        getInprogressOffersIspolnitel()
        getDoneOffersIspolnitel()
        getFavoriteOffersIspolnitel()
    }, [])

    const setUserHandler = (user) => {
        setUser(user)
    }

    const showOffersHandler = (type) => {
        setShowOffers(type)
    }

    const createOfferHandler = async () => {
        try {
            const response = await OfferService.createOffer(current)
            const offerData = response.data.offer
            console.log(offerData)
            navigate(EDIT_OFFER + '/' + offerData.id)
            toast.success('Заказ создан!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const getDraftOffers = async () => {
        try {
            const response = await OfferService.getDraftOffers(current)
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setDraftOffers(dataArray)
            } else {
                setDraftOffers([])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getDoneOffers = async () => {
        try {
            const response = await OfferService.getDoneOffers(current)
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setDoneOffers(dataArray)
            } else {
                setDoneOffers([])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getActiveOffers = async () => {
        try {
            const response = await OfferService.getActiveOffers(current)
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setActiveOffers(dataArray)
            } else {
                setActiveOffers([])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getInprogressOffers = async () => {
        try {
            const response = await OfferService.getInprogressOffers(current)
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setInprogressOffers(dataArray)
            } else {
                setInprogressOffers([])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getInprogressOffersIspolnitel = async () => {
        try {
            const response = await OfferService.getInprogressOffersIspolnitel(current)
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setInprogressOffersIsp(dataArray)
            } else {
                setInprogressOffersIsp([])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getDoneOffersIspolnitel = async () => {
        try {
            const response = await OfferService.getDoneOffersIspolnitel(current)
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setDoneOffersIsp(dataArray)
            } else {
                setDoneOffersIsp([])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getFavoriteOffersIspolnitel = async () => {
        try {
            const response = await OfferService.getFavoriteOffersIspolnitel(current)
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setFavoritesOffersIsp(dataArray)
            } else {
                setFavoritesOffersIsp([])
            }
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <>
            <div className={styles.content}>
                <p>Мои заказы</p>
                <div className={styles.offers}>
                    <div className={styles.creatorOrPermoncer}>
                        <button onClick={() => setUserHandler('ispolnitel')} className={user !== 'ispolnitel' ? styles.notselected : styles.selectUser}>
                            Я исполнитель
                        </button>
                        <button onClick={() => setUserHandler('zakazchik')} className={user !== 'zakazchik' ? styles.notselected : styles.selectUser}>
                            Я заказчик
                        </button>
                    </div>
                    {
                        user === 'zakazchik' &&
                        <button className={styles.createOffer} onClick={createOfferHandler}>
                            Разместить заказ
                        </button>
                    }
                    {
                        user === 'ispolnitel' &&
                        <button className={styles.createOffer} onClick={() => navigate(FREELANCE_CATALOG)}>
                            Перейти в каталог
                        </button>
                    }
                    <div className={styles.userOffers}>
                        {
                            user === 'zakazchik' &&
                            <>
                                <button className={styles.typeOffer} onClick={() => showOffersHandler('inprogress')} >
                                    <img src={play2} alt="" className={styles.leftIcon} />
                                    На исполнении
                                    <img src={showOffers === 'inprogress' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                                </button>

                                {
                                    (showOffers === 'inprogress' && inprogressOffers.length !== 0) &&
                                    <div className={styles.draftOffers}>
                                        {
                                            inprogressOffers.map((item, index) => (
                                                <>
                                                    <div key={index} className={styles.offerBlock} onClick={() => navigate(EDIT_OFFER + `/${item.id}`)}>
                                                        <p>{item.name}</p>
                                                        <img src={edit} alt="" className={styles.rightIcon} />
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                }

                                <button className={styles.typeOffer} onClick={() => showOffersHandler('published')}>
                                    <img src={check} alt="" className={styles.leftIcon} />
                                    Опубликованные
                                    <img src={showOffers === 'published' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                                </button>

                                {
                                    (showOffers === 'published' && activeOffers.length !== 0) &&
                                    <div className={styles.draftOffers}>
                                        {
                                            activeOffers.map((item, index) => (
                                                <>
                                                    <div key={index} className={styles.offerBlock} onClick={() => navigate(EDIT_OFFER + `/${item.id}`)}>
                                                        <p>{item.name}</p>
                                                        <img src={edit} alt="" className={styles.rightIcon} />
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                }

                                <button className={styles.typeOffer} onClick={() => showOffersHandler('draft')} >
                                    <img src={feather} alt="" className={styles.leftIcon} />
                                    Черновики
                                    <img src={showOffers === 'draft' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                                </button>
                                {
                                    (showOffers === 'draft' && draftOffers.length !== 0) &&
                                    <div className={styles.draftOffers}>
                                        {
                                            draftOffers.map((item, index) => (
                                                <>
                                                    <div key={index} className={styles.offerBlock} onClick={() => navigate(EDIT_OFFER + `/${item.id}`)}>
                                                        <p>{item.name}</p>
                                                        <img src={edit} alt="" className={styles.rightIcon} />
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                }

                                <button className={styles.typeOffer} onClick={() => showOffersHandler('done')} >
                                    <img src={archive} alt="" className={styles.leftIcon} />
                                    Выполненные
                                    <img src={showOffers === 'done' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                                </button>
                                {
                                    (showOffers === 'done' && doneOffers.length !== 0) &&
                                    <div className={styles.draftOffers}>
                                        {
                                            doneOffers.map((item, index) => (
                                                <>
                                                    <div key={index} className={styles.offerBlock} onClick={() => navigate(EDIT_OFFER + `/${item.id}`)}>
                                                        <p>{item.name}</p>
                                                        <img src={edit} alt="" className={styles.rightIcon} />
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                }

                            </>
                        }
                        {
                            user === 'ispolnitel' &&
                            <>
                                <button className={styles.typeOffer} onClick={() => showOffersHandler('inprogress')} >
                                    <img src={play2} alt="" className={styles.leftIcon} />
                                    На исполнении
                                    <img src={showOffers === 'inprogress' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                                </button>

                                {
                                    (showOffers === 'inprogress' && inprogressOffersIsp.length !== 0) &&
                                    <div className={styles.draftOffers}>
                                        {
                                            inprogressOffersIsp.map((item, index) => (
                                                <>
                                                    <div key={index} className={styles.offerBlock} onClick={() => navigate(EDIT_OFFER + `/${item.id}`)}>
                                                        <p>{item.name}</p>
                                                        <img src={edit} alt="" className={styles.rightIcon} />
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                }

                                <button className={styles.typeOffer} onClick={() => showOffersHandler('favorites')} >
                                    <img src={heart2} alt="" className={styles.leftIcon} />
                                    Избранное
                                    <img src={showOffers === 'favorites' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                                </button>

                                {
                                    (showOffers === 'favorites' && favoritesOffersIsp.length !== 0) &&
                                    <div className={styles.draftOffers}>
                                        {
                                            favoritesOffersIsp.map((item, index) => (
                                                <>
                                                    <div key={index} className={styles.offerBlock} onClick={() => navigate(EDIT_OFFER + `/${item.id}`)}>
                                                        <p>{item.name}</p>
                                                        <img src={edit} alt="" className={styles.rightIcon} />
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                }


                                <button className={styles.typeOffer} onClick={() => showOffersHandler('done')} >
                                    <img src={check} alt="" className={styles.leftIcon} />
                                    Выполненные
                                    <img src={showOffers === 'done' ? arrowUp : arrowDown} className={styles.rightIcon} alt="" />
                                </button>

                                {
                                    (showOffers === 'done' && doneOffersIsp.length !== 0) &&
                                    <div className={styles.draftOffers}>
                                        {
                                            doneOffersIsp.map((item, index) => (
                                                <>
                                                    <div key={index} className={styles.offerBlock} onClick={() => navigate(EDIT_OFFER + `/${item.id}`)}>
                                                        <p>{item.name}</p>
                                                        <img src={edit} alt="" className={styles.rightIcon} />
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                }

                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default observer(MyOffers)