import { IOffer } from "../models/IOffer";
import "react-toastify/dist/ReactToastify.css";
import { makeAutoObservable } from "mobx";
import OfferService from "../service/OfferService";
import { toast } from "react-toastify";

export default class OfferStore {

    offer = {} as IOffer

    constructor() {
        makeAutoObservable(this)
    }

    setOffer(offer: IOffer) {
        this.offer = offer
    }

    async createOffer(user_id: string) {
        try {
            const response = await OfferService.createOffer(user_id)
            this.setOffer(response.data.offer)
            toast.success('Заказ создан!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async getOffer(offer_id: string) {
        try {
            const response = await OfferService.getOffer(offer_id)
            this.setOffer(response.data.offer)
        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }

    async saveOfferData(formdata: any) {
        try {
            const response = await OfferService.saveOfferData(formdata)
            this.setOffer(response.data.offer)
            toast.success('Заказ сохранен!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e.response?.data?.message)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    async publishOffer(offer_id: string) {
        try {
            const response = await OfferService.publishOffer(offer_id)
            this.setOffer(response.data.offer)

        } catch (e) {
            console.log(e.response?.data?.message)
        }
    }
}
