export const PROFILE_ROUTE = '/persacc'
export const LANDING_ROUTE = '/'
export const CATALOG_ROUTE = '/course/catalog'
export const CREATE_ROUTE = '/create'
export const SETTINGS_ROUTE = '/settings'
export const FREE_COURSE = '/course/create'
export const COURSES_CONTENT = '/course/content'
export const COURSE_LESSONS = '/course/lessons'
export const COURSE_PAGE = '/course/page'
export const MODULE_PAGE = '/module'
export const EDITCOURSE_PAGE = '/course/edit'
export const TEST_PAGE = '/testpage'
export const EDIT_LESSON = '/editlesson'
export const PERSONAL_PAGE = '/personalpage'
export const AUTHORIZATION_PAGE = '/authuser'
export const COURSE_MANAGMENT = '/managment'
export const TRAINER_PAGE = '/trainers'
export const CREATE_TRAINER = '/trainer/create'
export const EDIT_TRAINER = '/trainer/edit'
export const MY_TRAINERS = '/mytrainers'
export const CATALOG_TRAINERS = '/trainer/catalog'
export const SOLVE_TRAINER = '/task'
export const POLICY_PAGE = '/privacypolicy'
export const LESSON_PAGE = '/lesson'
export const SIGNUP_PAGE = '/signup'
export const LOGIN_PAGE = '/login'
export const FAVORITE_COURSES = '/course/favorite'
export const COURSE_CARD = '/course'
export const LEARN_MAIN_PAGE = '/course/structure'
export const FREELANCE_CATALOG = '/freelance'
export const EDIT_OFFER = '/offer/edit'
export const LEARN = '/learn'
export const OFFER_PAGE = '/offer'

