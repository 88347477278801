import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Store from './store/store';
import { createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import CourseStore from './store/CoursesStore';
import TrainerStore from './store/TrainerStore';
import OfferStore from './store/offerStore';


interface State {
  store: Store,
  courseStore: CourseStore,
  trainerStore: TrainerStore,
  offerStore: OfferStore
}

const store = new Store();
const courseStore = new CourseStore()
const trainerStore = new TrainerStore()
const offerStore = new OfferStore()
export const Context = createContext<State>({
  store, courseStore, trainerStore, offerStore
})

ReactDOM.render(
  <Context.Provider value={{ store, courseStore, trainerStore, offerStore }}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ Context.Provider>,
  document.getElementById('root')
)


reportWebVitals();