import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Context } from "..";
import styles from '../styles/freelance.module.css'
import DocumentTitle from "react-document-title";
import BurgerMenu from "../components/BurgerMenu";
import OfferService from "../service/OfferService";
import heart from '../Images/heart.svg'
import redHeart from '../Images/redHeart.svg'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { OFFER_PAGE } from "../utils";

function FreelanceCatalog() {
    const navigate = useNavigate()
    const { store } = useContext(Context)

    const [allOffers, setAllOffers] = useState([])
    const [favoritesOffersIsp, setFavoritesOffersIsp] = useState([])

    useEffect(() => {
        getAllOffers()
        getFavoriteOffersIspolnitel()
    }, [])

    const navigateHandler = (path, id) => {
        if (path === 'offer') {
            navigate(OFFER_PAGE + '/' + id)
        }
    }

    const addToFavoriteHandler = async (id) => {
        try {
            const response = await OfferService.addToFavorite(store.user.id, id)
            toast.success('Успех', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        } catch (e) {
            toast.error('Ошибка, повторите позже', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            console.log(e)
        }
        getFavoriteOffersIspolnitel()
    }

    const getAllOffers = async () => {
        try {
            const response = await OfferService.getAllOffers()
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setAllOffers(dataArray)
            } else {
                setAllOffers([])
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getFavoriteOffersIspolnitel = async () => {
        try {
            const response = await OfferService.getFavoriteOffersIspolnitel(localStorage.getItem('userId'))
            const dataArray = response.data.offers
            if (Array.isArray(dataArray)) {
                setFavoritesOffersIsp(dataArray)
            } else {
                setFavoritesOffersIsp([])
            }
        } catch (e) {
            console.log(e)
        }
    }


    return (
        <DocumentTitle title="Фриланс">
            <>

                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>
                    <div className={`${styles.content} `}>
                        <BurgerMenu />

                        <div className={styles.offers}>
                            <p>Каталог</p>
                            {
                                allOffers.map((item, index) => (
                                    <>
                                        <div key={index} className={styles.offer}>
                                            <div className={styles.info}>
                                                <p>{item.name}</p>
                                                <div className={styles.description}>
                                                    <p>{item.description}</p>
                                                </div>
                                                <div>
                                                    <p className={styles.price}>{item.price === '0' ? 'Бесплатно' : item.price}</p>
                                                </div>
                                            </div>
                                            <div className={styles.more}>
                                                <button className={styles.podrobnee} onClick={() => navigateHandler('offer', item.id)}>
                                                    Подробнее
                                                </button>
                                                <button className={styles.addToFavorite} onClick={() => addToFavoriteHandler(item.id)}>
                                                    {
                                                        !favoritesOffersIsp.some(i => i.id === item.id) &&
                                                        < img src={heart} alt="" />
                                                    }

                                                    {
                                                        favoritesOffersIsp.some(i => i.id === item.id) &&
                                                        < img src={redHeart} alt="" />
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        </DocumentTitle>
    )
}

export default observer(FreelanceCatalog)
