import React, { useContext, useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Context } from "..";
import { useParams } from "react-router-dom";
import styles from '../styles/offerPage.module.css'
import Header from "../components/Header";
import BurgerMenu from "../components/BurgerMenu";
import Footer from "../components/Footer";
import OfferService from "../service/OfferService";
import { toast } from "react-toastify";
function OfferPage() {
    const { offerStore, store } = useContext(Context)

    const params = useParams()
    const current = params.id

    const [description, setDescription] = useState(offerStore.offer.description)
    const [name, setName] = useState(offerStore.offer.name)
    const [employer, setEmployer] = useState('')
    const [isOfferSend, setIsOfferSend] = useState('')

    useEffect(() => {
        GetOffer()
        GetEmployer()
        GetOfferResponse()
    }, [])

    const GetOffer = async () => {
        try {
            await offerStore.getOffer(current)
            setDescription(offerStore.offer.description)
            setName(offerStore.offer.name)
        } catch (e) {
            console.log(e)
        }
    }

    const GetEmployer = async () => {
        try {
            const response = await OfferService.getEmployer(current)
            setEmployer(response.data.employer)
        } catch (e) {
            console.log(e)
        }
    }

    const GetOfferResponse = async () => {
        try {
            const response = await OfferService.getOfferResponse(current, store.user.id)
            setIsOfferSend(response.data.offerPeople)
        } catch (e) {
            console.log(e)
        }
    }


    const responseOfferHanlder = async () => {
        try {
            await OfferService.sendOfferResponse(current, store.user.id)
            toast.success('Успешно!', {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (e) {
            console.log(e)
            toast.warning(e.response?.data?.message, {
                position: "top-center",
                autoClose: 500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        GetOfferResponse()
    }

    return (
        <DocumentTitle title={offerStore.offer.name}>
            <>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>

                    <div className={styles.content}>
                        <BurgerMenu />
                        <div className={styles.offer}>
                            <div className={styles.name}>
                                {offerStore.offer.name}
                            </div >
                            <div className={styles.zakazchik}>
                                <p>Заказчик:</p>
                                <div className={styles.employerInfo}>
                                    <img src={process.env.REACT_APP_API_URL + employer.img} alt="" />
                                    <div>
                                        <div>
                                            {employer.name}
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.description}>
                                <p>Описание</p>
                                <div className={styles.offerContent}>
                                    {offerStore.offer.description}

                                </div>
                            </div>
                            {
                                isOfferSend === '' &&
                                <button className={styles.otkilkButton} onClick={responseOfferHanlder}>
                                    Откликнуться
                                </button>
                            }
                            {
                                isOfferSend !== '' &&

                                <>
                                    <button className={styles.otkilkButtonDisabled} disabled={true}>
                                        Откликнуться
                                    </button>
                                    <p className={styles.alredySend}>Вы уже откликнулись на этот заказ</p>
                                </>
                            }

                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        </DocumentTitle >
    )
}

export default OfferPage