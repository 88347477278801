import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Context } from "..";
import styles from '../styles/editOffer.module.css'
import Header from "../components/Header";
import BurgerMenu from "../components/BurgerMenu";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";
import pen from '../Images/pen.svg'
import errorimg from '../Images/error.svg'
import { icons } from "../data/coursesData";
import plus from '../Images/plus.svg'
import exit from '../Images/exit.svg'
import people from '../Images/people.svg'

function EditOffer() {
    const { offerStore } = useContext(Context)

    const params = useParams()
    const current = params.id

    const [panel, setPanel] = useState('main')
    const [error, setError] = useState('')
    const [dopType, setDopType] = useState([])
    const [showTags, setShowTegs] = useState(false)
    const [tags, setTags] = useState([])
    const [description, setDescription] = useState(offerStore.offer.description)
    const [name, setName] = useState(offerStore.offer.name)


    useEffect(() => {
        GetOffer()
    }, [])

    const GetOffer = async () => {
        try {
            await offerStore.getOffer(current)
            setDescription(offerStore.offer.description)
            setName(offerStore.offer.name)
            console.log('Done')
        } catch (e) {
            console.log(e)
        }
    }

    const panelHandler = (panel) => {
        setPanel(panel)
    }

    const addDopTypesHandler = (id) => {
        setDopType(prev => [...prev, id])
        setShowTegs(false)
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const removedopTegs = (index) => {
        setDopType(dopType.filter(item => (item !== index)))
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const descriptionHandler = (e) => {
        setDescription(e.target.value)
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const nameHandler = (e) => {
        setName(e.target.value)
        setError('Данные были изменены. Не забудьте сохранить.')
    }

    const saveData = () => {
        const formdata = new FormData()
        formdata.append('offer_id', current)
        formdata.append('name', name)
        formdata.append('description', description)
        dopType.forEach((index, item) => {
            formdata.append('additional_type', index)
        })
        offerStore.saveOfferData(formdata)
        setError('')
    }

    const findTag = (e) => {
        let pattern = e.target.value.toLowerCase(); // Приводим строку поиска к нижнему регистру
        let result = icons.filter(item => item.name.toLowerCase().startsWith(pattern));
        setTags(result);
    }

    const showTagsHandler = () => {
        setShowTegs(true)
    }

    const publishOfferHandler = async () => {
        try {
            await offerStore.publishOffer(current)
        } catch (e) {
            console.log(e)
        }
        GetOffer()
    }

    return (
        <DocumentTitle title={offerStore.offer.name}>
            <>
                <div className={styles.container}>
                    <div>
                        <Header />
                    </div>

                    <div className={styles.content}>
                        <BurgerMenu />
                        <div className={styles.offer}>
                            <div className={styles.navPanel}>
                                <p>{offerStore.offer.name}</p>
                                <div className={styles.navButtons}>
                                    <button onClick={() => panelHandler('main')} className={panel === 'main' ? `${styles.selected}` : false}>
                                        <img src={pen} alt="" />
                                        <p>Основное</p>
                                    </button>
                                    <button onClick={() => panelHandler('feedback')} className={panel === 'feedback' ? `${styles.selected}` : false}>
                                        <img src={people} alt="" />
                                        <p>Отклики</p>
                                    </button>
                                    {/* <button onClick={() => panelHandler('publicate')} className={panel === 'publicate' ? `${styles.selected}` : false}>
                                        <img src={catalog2} alt="" />
                                        <p>Размещение в каталоге</p>
                                    </button> */}
                                </div>
                                <div className={styles.saveAndError}>
                                    {
                                        error &&
                                        <div className={styles.error}>
                                            <img src={errorimg} alt="" />
                                            <div>
                                                <p>Есть несохраненные изменения</p>
                                                <div>
                                                    Не забудьте сохранить их перед выходом
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className={styles.saveButton} onClick={saveData}>
                                        Сохранить изменения
                                    </div>

                                    <div className={styles.saveButton} onClick={publishOfferHandler}>
                                        {
                                            offerStore.offer.status === 'notpublished' &&

                                            <>
                                                Опубликовать
                                            </>
                                        }
                                        {
                                            offerStore.offer.status === 'published' &&
                                            <>
                                                Снять с публикации
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={styles.offerInfo}>
                                {
                                    panel === 'main' &&
                                    <>
                                        <div className={styles.mainOfferInfo}>
                                            <p>Внешний вид</p>
                                            <div className={styles.mainInfo}>
                                                <div className={styles.aboutOffer}>
                                                    <p>Общая информация</p>
                                                    <div className={styles.descriptionNameTags}>
                                                        <p>Название заказа</p>
                                                        <input
                                                            type="text"
                                                            className={styles.offerName}
                                                            placeholder="Введите текст"
                                                            onChange={(e) => nameHandler(e)}
                                                            value={name}
                                                        />
                                                        <p className={styles.tagName}>Описание заказа</p>
                                                        <textarea
                                                            className={styles.shortDescription}
                                                            placeholder="Введите текст"
                                                            name=""
                                                            id=""
                                                            onChange={(e) => descriptionHandler(e)}
                                                            value={description}
                                                        >
                                                        </textarea>
                                                        <p className={styles.tagName}>Теги</p>
                                                        <div className={styles.tags}>
                                                            <div className={styles.tagBlock}>
                                                                <button onClick={showTagsHandler}>
                                                                    <img src={plus} alt="" />
                                                                </button>
                                                                {
                                                                    dopType.length !== 0 &&

                                                                    icons.filter(item => dopType.includes(item.id)).map(item => (
                                                                        <div key={item.id}>

                                                                            <div className={styles.tegblock}>
                                                                                {item.name}
                                                                                <img
                                                                                    src={exit} alt=""
                                                                                    onClick={() => removedopTegs(item.id)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))

                                                                }

                                                            </div>
                                                            {
                                                                showTags &&
                                                                <div className={styles.searchTags}>
                                                                    <input
                                                                        type="text"
                                                                        onChange={(e) => findTag(e)}
                                                                        placeholder="Поиск"
                                                                    />
                                                                    {
                                                                        tags.length !== 0 &&
                                                                        <div className={styles.tagNames}>
                                                                            {
                                                                                tags.map((item, index) => (
                                                                                    <div key={index} onClick={() => addDopTypesHandler(item.id)} >
                                                                                        {item.name}
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </div>
            </>
        </DocumentTitle>

    )
}

export default observer(EditOffer)